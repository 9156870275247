<template>
  <!-- modal -->
  <div class="modal" v-bind:class="{open: article}" v-if="article">
    <div class="modal_box">
      <div class="header">
        <h4>1点の商品が<br class="sp">カートに追加されました</h4>
        <div class="close modal_close" @click="$emit('canceled')"></div>
      </div>
      <div class="inner draft cart">
        <div class="scroll_wrap">
          <div class="scroll_box">
            <h4 class="bold mb10">以下の商品がカートに追加されました。</h4>
            <div class="item_detail flex">
              <div class="image"><img v-bind:src="article.image_files[0]" alt=""></div>
              <h5>{{ article.title }}</h5>
            </div>
            <div class="btn_wrap">
              <router-link :to="{ name: 'ShoppingCart'}" class="submit light cart">
                カートを見る
              </router-link>
            </div>
            <div class="btn_wrap">
              <a href="javascript:void(0)" @click="$emit('canceled')" class="submit light skeleton">買い物を続ける</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ovr"></div>
  </div>
  <!-- modal -->
</template>

<script>

export default {
  props: {
    article: null
  }
}
</script>
